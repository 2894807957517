<template>
  <v-app>
    <b-modal id="modal-scrollable" size="xl" ref="my-modal" hide-footer :title="`${edit?'Update':'Add'} Faculty`">
      <div class="row">
        <div class="col-12">
          <v-text-field outlined dense label="Faculty Title" v-model="faculty.title"></v-text-field>
          <span class="text-danger" v-if="$v.faculty.title.$error">Title is required</span>
        </div>

        <div class="col-12 mt-3">
          <v-textarea outlined dense label="Description" v-model="faculty.description"></v-textarea>
        </div>
        <div class="col-3  mt-5">
          <b-form-checkbox v-model="faculty.is_active" name="check-button" class switch>Status</b-form-checkbox>
        </div>

        <div class="col-12 text-right mt-4">
          <v-btn
              color="red"
              class="text-gray btn btn-standard"
              depressed
              @click="hideModal"
          >Cancel
          </v-btn>
          <v-btn
              v-if="checkIsAccessible('faculty', 'create')"
              class="text-white ml-2 btn btn-primary"
              depressed
              @click="createAndUpdate"
              :loading="isBusy"
          >Save
          </v-btn>

        </div>
      </div>
    </b-modal>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import FacultyService from "@/core/services/faculty/FacultyService";

const facultyService = new FacultyService();

export default {
  props: ["message_id"],
  name: "faculty-create-and-update",
  validations: {
    faculty: {
      title: {required}
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      faculty: {
        title: null,
        is_active: true
      }
    };
  },
  methods: {
    getFaculty(id) {
      facultyService.show(id).then(response => {
        this.edit = true;
        this.faculty = response.data.faculty;
        this.faculty.is_active = this.faculty.is_active ? true : false
      });
    },
    showModal(id = null) {
      if (id) {
        this.getFaculty(id);
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = [];
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.faculty.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateFaculty();
        } else {
          this.createFaculty();
        }
      }
    },
    createFaculty() {
      facultyService.store(this.faculty).then(response => {
        this.resetFaculty()
        this.$emit("close_dialog");
      });
    },
    updateFaculty() {
      facultyService.update(this.faculty.id, this.faculty).then(response => {
        this.resetFaculty()
        this.$emit("close_dialog");
      });
    }, resetFaculty() {
      this.faculty = {
        title: null
      }
      this.$v.$reset()
    }
  }
};
</script>
