<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4>Manage Faculty</h4>
                              <router-link :to="{ name: 'dashboard' }">
                                Dashboard
                              </router-link>
                              \
                              Faculty
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                                <button @click="openDialog()" class="btn btn-primary ml-2">

                                    Add faculty
                                </button>
                            </div>
                            <div class="col-12">
                                <div class="">
                                    <table class="table table-stripe">
                                        <thead>
                                        <th>Title</th>
<!--                                        <th>Active</th>-->
                                        <th>Action</th>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(faculty, index) of faculties" :key="index">
                                            <td> <a href="#" class="navi-link"
                                                    @click="openDialog(faculty.id)"> {{faculty.title}} </a><i class="fas fa-circle" :class="page.is_active?'dot-active':'dot-inactive'"></i></td>
<!--                                            <td>-->
<!--                                                <i-->
<!--                                                        class="fas fa-circle"-->
<!--                                                        :class="faculty.is_active?'text-success':'text-danger'"-->
<!--                                                ></i>-->
<!--                                            </td>-->
                                            <td>
                                                <b-dropdown
                                                        size="sm"
                                                        variant="link"
                                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                        no-caret
                                                        right
                                                        no-flip
                                                >
                                                    <template v-slot:button-content>
                                                        <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                                    </template>
                                                    <!--begin::Navigation-->
                                                    <div class="navi navi-hover ">


                                                        <b-dropdown-text tag="div" class="navi-item">
                                                            <router-link class="navi-link"
                                                                         :to='{name:"faculty-teacher", params:{id:faculty.id}}'>
                                                                   <span class="navi-icon">
                                                                       <i class="fas fa-eye"></i>
                                                                   </span>
                                                                <span class="navi-text"> View </span>
                                                            </router-link>

                                                        </b-dropdown-text>
                                                      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('faculty', 'edit')">
                                                        <a href="#" class="navi-link"
                                                           @click="openDialog(faculty.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                          <span class="navi-text">  Edit</span>
                                                        </a>
                                                      </b-dropdown-text>
                                                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('faculty', 'delete')">
                                                            <a href="#" class="navi-link"
                                                               @click="deleteFaculty(faculty.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                                <span class="navi-text">  Delete</span>
                                                            </a>
                                                        </b-dropdown-text>

                                                    </div>
                                                    <!--end::Navigation-->
                                                </b-dropdown>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row" v-if="faculties.length>0">
                                <div class="col-12 text-center">
                                    <b-pagination
                                            @input="getFaculties"
                                            v-model="page"
                                            :total-rows="total"
                                            :per-page="perPage"
                                            first-number
                                            last-number
                                    ></b-pagination>
                                    <!--  -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <create-and-update ref="faculty" :faculty_id="faculty_id" @close_dialog="closeDialog"></create-and-update>
        </div>
    </v-app>
</template>
<script>
    import FacultyService from "@/core/services/faculty/FacultyService";

    import CreateAndUpdate from "./CreateAndUpdate";

    const facultyService = new FacultyService();

    export default {
        name: "Insitutions",
        components: {
            CreateAndUpdate
        },
        data() {
            return {
                faculties: [],
                faculty_id: null,
                page: null,
                total: null,
                perPage: null
            };
        },
        mounted() {
            this.getFaculties();
        },
        methods: {
            getFaculties() {
                facultyService.paginate().then(response => {
                    this.faculties = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                });
            },
            openDialog(id) {
                this.faculty_id = id;
                this.$refs["faculty"].showModal(id);
            },
            closeDialog(id) {
                this.faculty_id = id;
                this.$refs["faculty"].hideModal();
                this.getFaculties();
                this.faculty_id = null;
            },
            deleteFaculty(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            facultyService.delete(id).then(response => {
                                this.$snotify.success("Information deleted");
                                this.getFaculties();
                            });
                        }
                    }
                });
            }
        }
    };
</script>
